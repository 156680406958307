import React from "react";
import clsx from "clsx";
import { MUIDataTableColumnDef } from "mui-datatables";
import {
  DataItem,
  FilterParam,
  ObjectDataFilterBody,
  ObjectDefinition,
  ObjectDefinitionField,
  RemoteObjectData,
} from "../../datastore/dataStoreTypes";
import { fetchWrapper } from "../../core/fetchWrapper";
import { getGrapesJSForm } from "../../services/flow/FFormService";
import { showNotification } from "../../util/notification";
import { fetchForAllDictionaries } from "../../services/DictionayService";
import i18n from "../../i18n";
import { fetchForDownloadData, fetchForFilterData } from "../../datastore/objectDataReducer";
import { SortParams, FilterType } from "../../types/commonTypes";
import { stylesForTableOptions } from "./objectDataStyles";
import {
  DATE,
  DECIMAL,
  EMPTY,
  ENTER,
  INTEGER,
  STATUS_CODES,
  REGEX_MM_DD_YYYY,
  REGEX_YYYY_MM_DD,
  REGEX_YYYY_MM,
  REGEX_MM_YYYY,
  REGEX_YYYY,
  REGEX_DD_MM,
  LAST_UPDATED,
  EQUALS,
} from "../../constants";
import { CustomToolbarProps } from "./reportTypes";
var FileSaver = require("file-saver");
import { toLocalDateTime, toLocaleShortDate } from "../../util/dateUtils";
import { TextField } from "@material-ui/core";
import { scope, Document, DocumentParams } from "../../redux/types";
import { fetchForAllDocuments, generateDoc } from "../../services/DocumentService";
import { downloadFile } from "../../redux/selectors/documentSelector";
import Log from "../../core/Log";
import moment from "moment";
import { CUT, GRAPES_JS_FORM } from "../TeamTask/constants";
import { fetchCombinedUserTaskModelData } from "../tasks/combinedusertask";

const { FORBIDDEN, NOT_AUTHENTICATED, SERVER_TOO_BUSY, SUCCESS, UN_PROCESSABLE_ENTITY } = STATUS_CODES;

export const getColumnsArray = (
  objectDefinition: ObjectDefinition,
  isMobile: boolean,
  filterList: any,
  { direction, name }: SortParams,
  applyFilter: (filterList: any[]) => void
): MUIDataTableColumnDef[] | any => {
  const classes = stylesForTableOptions();
  var columnsArray: MUIDataTableColumnDef[] = [
    {
      name: "JUAKALI_BO_ID",
      label: "JUAKALI_BO_ID",
      options: {
        display: "false",
        filter: false,
        download: false,
      },
    },
    {
      name: LAST_UPDATED,
      label: i18n.t(`reports.${LAST_UPDATED}`),
      options: {
        sort: true,
        setCellProps: () => ({
          align: EMPTY,
        }),
        setCellHeaderProps: () => ({
          className: classes.disableSort,
        }),
        sortDirection: name === LAST_UPDATED ? direction : "none",
        customBodyRender: value => toLocalDateTime(value),
      },
    },
  ];

  const filterType: FilterType = { type: "custom" };
  let i = 2;
  objectDefinition.fields.map((x: ObjectDefinitionField) => {
    const { visibleInMobile, visibleInOthers, key, type, label } = x;
    if ((isMobile && visibleInMobile) || (visibleInOthers && !isMobile)) {
      var column = {
        name: key,
        label: label,
        options: {
          filter: true,
          fieldType: type,
          filterType: filterType.type,
          sort: true,
          setCellProps: () => ({
            align: (type === INTEGER || type === DECIMAL || type === DATE) && !isMobile ? "right" : EMPTY,
          }),
          setCellHeaderProps: () => {
            return {
              className: clsx({
                [classes.justifyTableHead]: type === INTEGER || type === DECIMAL || type === DATE ? true : false,
              }),
            };
          },
          filterList: filterList ? filterList[i] : [],
          sortDirection: key === name ? direction : "none",
          customBodyRender: (value: any) => {
            if (type === DATE && value) {
              return toLocaleShortDate(value);
            }
            return value;
          },
          filterOptions: {
            display: (filterList: any, onChange: any, index: any, column: any) => {
              const { name, label } = column;
              return (
                <TextField
                  type="text"
                  name={name}
                  variant="standard"
                  label={label}
                  autoComplete="off"
                  onKeyDown={e => {
                    if (e.keyCode == ENTER) {
                      applyFilter(filterList);
                    }
                  }}
                  onChange={e => {
                    const { value } = e.target;
                    value ? (filterList[index][0] = value) : (filterList[index] = []);
                    onChange(filterList[index], index, column);
                  }}
                  value={(filterList[index] && filterList[index][0]) || EMPTY}
                />
              );
            },
          },
        },
      };
      columnsArray.push(column);
      i = i + 1;
    }
  });
  return columnsArray;
};
export const getGrapesFormModel = (formType: any, modelId: string, setFormData: any): any => {
  var promise = null;
  const FORMTYPE = formType;
  const modelData = FORMTYPE === GRAPES_JS_FORM ? getGrapesJSForm : FORMTYPE === CUT ? fetchCombinedUserTaskModelData : null;

  if (modelData) {
    promise = fetchWrapper(modelData, modelId);

    promise &&
      promise
        .then((res: any) => {
          if (res.status === SUCCESS) {
            return res.json();
          } else {
            if (res.status !== NOT_AUTHENTICATED) {
              throw new Error();
            }
          }
        })
        .then((result: any) => {
          setFormData(result);
        })
        .catch(() => {
          showNotification("error", i18n.t("datastore.reportFormError"));
        });
  }
};

export const handleExportClick = async (props: CustomToolbarProps) => {
  let { objectDefinitionKey, objectDefinitionLabel, version, filterParams, setState, searchType } = props;
  setState(state => ({ ...state, isExcelDownloading: true }));
  const stopLoader = () => setState(state => ({ ...state, isExcelDownloading: false }));
  try {
    var response: Response = await fetchWrapper(fetchForDownloadData, objectDefinitionKey, version, filterParams, searchType);
    const { status } = response;
    if (status === SUCCESS) {
      const body = await response.blob();
      FileSaver.saveAs(body, `${objectDefinitionLabel}.xlsx`);
      stopLoader();
    } else if (status === UN_PROCESSABLE_ENTITY) {
      setState(state => ({ ...state, isExcelDownloading: false, showReportDownloadError: true }));
    } else {
      if (status !== NOT_AUTHENTICATED) {
        throw new Error();
      }
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
    stopLoader();
  }
};

export const loadDictionaries = (setDictionaries: any): any => {
  var promise = fetchWrapper(fetchForAllDictionaries);
  promise
    .then((res: any) => {
      if (res.status === SUCCESS) {
        return res.json();
      } else {
        if (res.status !== NOT_AUTHENTICATED) {
          throw new Error();
        }
      }
    })
    .then((result: any) => {
      if (result) setDictionaries(result.data);
    })
    .catch(() => {
      showNotification("error", i18n.t("common.serverErrorTryAgain"));
    });
};

export const getDocuments = async (setDocuments: React.Dispatch<React.SetStateAction<Document[] | undefined>>, scope: scope) => {
  try {
    const response: Response = await fetchWrapper(fetchForAllDocuments, scope);
    const result = await response.json();
    const { status } = response;
    if (status === SUCCESS) {
      setDocuments(result.data);
    } else {
      if (status !== NOT_AUTHENTICATED && status !== FORBIDDEN) {
        throw new Error(EMPTY);
      }
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  }
};

export const filterRemoteObjectDataList = async (
  setRemoteObjectData: React.Dispatch<React.SetStateAction<RemoteObjectData>>,
  setRerenderTable: React.Dispatch<React.SetStateAction<boolean>>,
  key: string,
  filterBody: ObjectDataFilterBody
) => {
  try {
    setRerenderTable(true);
    const response: Response = await fetchWrapper(fetchForFilterData, key, filterBody);
    const result = await response.json();
    const { status } = response;
    if (status === SUCCESS) {
      const { content, page, pageSize, totalElements: count } = result.result;
      setRemoteObjectData({ count, content, page, pageSize });
    } else {
      if (status !== NOT_AUTHENTICATED && status !== FORBIDDEN) {
        throw new Error(EMPTY);
      }
    }
  } catch (e) {
    showNotification("error", i18n.t("common.serverErrorTryAgain"));
  } finally {
    setRerenderTable(false);
  }
};

export const generateDocument = async (params: DocumentParams) => {
  const { processInstanceId, contentId, setStatusForDocumentGeneration, type, documentType } = params;
  try {
    const response: Response = await fetchWrapper(generateDoc, {
      processInstanceId,
      contentId,
      documentType,
    });
    const { status } = response;
    setStatusForDocumentGeneration(status);
    if (response.status === SUCCESS) {
      const body: any = await response.blob();
      downloadFile(body, params);
    } else {
      throw new Error(String(response.status));
    }
  } catch (error) {
    const { message } = error as { message: any };
    const notifyOnApiError = message != SERVER_TOO_BUSY && message != NOT_AUTHENTICATED && message != FORBIDDEN && type == "reports";
    setStatusForDocumentGeneration(message);
    notifyOnApiError ? showNotification("error", i18n.t("common.serverErrorTryAgain")) : undefined;
    Log.error("Status: " + error);
  }
};

export const getFirstColumnKey = (isMobile: boolean, objectDefinition: ObjectDefinition) => {
  const field = objectDefinition.fields.find(({ visibleInMobile, visibleInOthers }) => {
    if ((isMobile && visibleInMobile) || (visibleInOthers && !isMobile)) {
      return true;
    }
  });
  return field ? field.key : EMPTY;
};

const tranformPartialDateFormat = (partialDate: string) => {
  const dateArray = partialDate.split("");
  const month = dateArray.slice(0, 2).join("");
  const year = dateArray.slice(3).join("");
  const finalYear = year.concat("-", month);
  return finalYear;
};

const getTransformedDate = (data: string) => {
  switch (true) {
    case !!data.match(REGEX_MM_DD_YYYY) || !!data.match(REGEX_YYYY_MM_DD):
      return moment(data).format("YYYY-MM-DD");
    case !!data.match(REGEX_YYYY_MM):
      return moment(data).format("YYYY-MM");
    case !!data.match(REGEX_DD_MM):
      return moment(data, "DD/MM").format("MM-DD");
    case !!data.match(REGEX_MM_YYYY):
      return tranformPartialDateFormat(data);
    case !!data.match(REGEX_YYYY):
      return data;
    default:
      return data;
  }
};

export const getFilterParams = (columnsArray: DataItem[], filterList: string[][], comparisonTypeParam?: string): FilterParam[] | any[] => {
  if (!filterList) return [];
  let filterParams: FilterParam[] = [];
  columnsArray.forEach((column, index) => {
    const { filter, fieldType } = column.options;
    if (filter === true) {
      const value = filterList[index] ? filterList[index][0] : undefined;
      if (value) {
        filterParams.push({
          key: column.name,
          value: fieldType === DATE ? getTransformedDate(value) : value,
          comparisonType: comparisonTypeParam || EQUALS,
        });
      }
    }
  });
  return filterParams;
};
export const getFilterProperties = (isMobile: boolean, objectDefinition?: ObjectDefinition) =>
  objectDefinition
    ? objectDefinition.fields.filter(({ visibleInMobile, visibleInOthers }) => (isMobile ? visibleInMobile : visibleInOthers)).map(field => field.key)
    : [];
