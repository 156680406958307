import { createMuiTheme, makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { TaskAssignment } from "../../../types/taskQueryTypes";
import { ALERT_MESSAGE_TEXT, FORM_LOADER_BG_COLOR, black, cancelColor, cobalt, graniteGray, rgbaLightGrey, solitude, white } from "../../theme";

export const taskTableTheme = (isMobileView: boolean, assignment: string, data: any): any => {
  const isTeamView = assignment === TaskAssignment.TEAM;
  return (createMuiTheme as any)({
    overrides: {
      MuiToolbar: {
        root: {
          borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
        },
        gutters: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      },
      MuiTablePagination: {
        toolbar: {
          borderBottom: "none",
        },
      },
      MuiPaper: {
        elevation4: {
          padding: isMobileView ? "24px 16px 4px 16px" : "24px 24px 4px 24px",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
          border: "1px solid #4F4F51",
        },
      },
      MuiListItemText: {
        root: {
          flex: "unset",
        },
      },
      MuiTypography: {
        subheading: {
          fontSize: "14px",
          fontWeight: 600,
        },
        title: {
          fontSize: "32px",
        },
      },
      MuiListItemIcon: {
        root: {
          marginRight: 0,
        },
      },
      MuiTableRow: {
        head: {
          height: "56px",
        },
        root: {
          height: "52px",
          "&:hover": {
            backgroundColor: isTeamView && data ? `${blue[50]} !important` : "unset !important",
          },
          "&.Mui-selected": {
            backgroundColor: `${white} !important`,
          },
        },
      },
      MUIDataTable: {
        responsiveScrollMaxHeight: {
          overflowY: isTeamView ? "hidden" : "",
          maxHeight: isTeamView ? "100% !important" : "",
        },
      },
      MUIDataTableBody: {
        emptyTitle: {
          textAlign: "left",
        },
      },
      MuiTableCell: {
        head: {
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "14px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "rgba(0, 0, 0, 1)",
          padding: "8px 0 8px 32px",
          "&:last-child": {
            paddingLeft: `${isTeamView ? 16 : 32}px !important`,
            paddingRight: "16px",
            textAlign: isTeamView ? "left" : "end",
          },
          "&:first-child": {
            paddingLeft: isTeamView ? undefined : "16px !important",
          },
          "&:nth-child(2)": {
            paddingLeft: isTeamView ? "16px !important" : undefined,
          },
        },
        root: {
          border: "unset",
        },
        footer: {
          display: "table-cell !important",
          borderBottom: "none !important",
        },
        body: {
          verticalAlign: "top",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0.02em",
          padding: isMobileView ? "8px 0 8px 32px" : "15px 0px 15px 32px",
          borderBottom: "1px solid rgba(0,0,0,1)",
          "&:last-child": {
            textAlign: isTeamView ? "start" : "end",
            paddingRight: isTeamView ? "" : "16px",
            paddingLeft: isTeamView ? "16px" : undefined,
          },
          "&:first-child": {
            paddingLeft: isTeamView ? undefined : "16px !important",
            padding: isTeamView ? "6px 0px 0px 6px !important" : undefined,
          },
          "&:nth-child(2)": {
            paddingLeft: isTeamView ? "16px !important" : undefined,
          },
        },
      },
      MUIDataTablePagination: {
        root: {
          paddingTop: "12px !important",
          "&:hover": {
            backgroundColor: "#fff",
          },
        },
      },
      MUIDataTableToolbar: {
        root: {
          display: "block",
        },
        left: {
          padding: "0 0 16px 0 !important",
        },
      },
      MUIDataTableHeadCell: {
        root: {
          "&:nth-child(1)": {
            width: isMobileView ? "20%" : "10%",
          },
          "&:nth-child(2)": {
            width: isMobileView ? (isTeamView ? "20%" : "80%") : assignment === TaskAssignment.INVOLVED ? "10%" : isTeamView ? "10%" : "30%",
          },
          "&:nth-child(3)": {
            maxWidth: assignment === TaskAssignment.INVOLVED ? "50%" : "",
            width: isMobileView ? "" : assignment === TaskAssignment.INVOLVED ? "" : "30%",
          },
          "&:nth-child(4)": {
            width: isMobileView ? "" : assignment === TaskAssignment.INVOLVED ? "" : isTeamView ? "30%" : "25%",
          },
          "&:nth-child(5)": {
            width: isTeamView ? "25%" : undefined,
          },
        },
        sortAction: {
          alignSelf: "center",
        },
        fixedHeader: {
          boxShadow: `inset 0 2px 0 ${black}, inset 0 -2px 0 ${black}`,
          zIndex: 6, // Give the z-index lesser than mdc-dialog z-index , so that the headers are blurred
        },
      },
      MUIDataTableSelectCell: {
        fixedHeader: {
          boxShadow: `inset 0 2px 0 ${black}, inset 0 -2px 0 ${black}`,
          zIndex: 6,
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          "&.Mui-checked": {
            color: cancelColor,
          },
        },
        indeterminate: {
          color: cancelColor,
        },
        root: {
          color: rgbaLightGrey,
        },
      },
    },
  });
};

export const loaderStyle = {
  position: "absolute" as any,
  zIndex: 110,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: FORM_LOADER_BG_COLOR,
};

export const formLoaderStyle = {
  position: "fixed" as any,
  zIndex: 110,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: FORM_LOADER_BG_COLOR,
};
export const loaderLogoStyle = {
  position: "fixed" as any,
  zIndex: 110,
  top: "40%",
  left: "55%",
};
export const loaderWrapper = {
  position: "relative" as any,
  paddingBottom: "2em",
};

export const wrapper = {
  position: "relative" as any,
};

export const selectBarStyle = makeStyles({
  bar: { backgroundColor: solitude, borderRadius: 10, display: "flex", height: 50, marginBottom: 5, padding: "0px 20px" },
  countBox: { backgroundColor: cobalt, color: white, display: "flex" },
  countText: { margin: "0px 16px 0px 8px" },
  flex: { display: "flex" },
  warningMsg: { color: ALERT_MESSAGE_TEXT, fontSize: 14, marginBottom: 5, marginLeft: 0, textAlign: "left" },
});

export const assigneeDialogTheme = (createMuiTheme as any)({
  overrides: {
    MuiAutocomplete: {
      popupIndicator: {
        marginRight: 4,
      },
      root: {
        width: "300px !important",
      },
    },
    MuiDialogContent: {
      dividers: { padding: 24 },
      root: { height: "165px !important" },
    },
    MuiTypography: {
      h6: {
        fontSize: "17px",
        fontWeight: 550,
        lineHeight: "23.44px",
      },
    },
  },
});

export const confirmDialogTheme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      dividers: { border: "none !important", padding: "6px 24px" },
    },
    MuiPaper: {
      root: { color: black },
    },
    MuiTypography: {
      h6: {
        fontSize: "17px",
        fontWeight: 550,
        lineHeight: "23.44px",
      },
    },
  },
});

export const selectBarTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: { padding: 0 },
    },
    MuiTooltip: {
      tooltip: {
        color: white,
        backgroundColor: graniteGray,
        fontSize: "12px",
        fontWeight: 500,
        fontStyle: "normal",
      },
      tooltipPlacementBottom: { margin: "10px !important" },
    },
  },
});
