import Dictionary from "../collections/Dictionary";
import { EMPTY, LAST_UPDATED } from "../constants";
import { EntityLoadingStatus, EntitySavingStatus } from "../genericRedux/reduxTypes";
import i18n from "../i18n";
import { SortParams } from "../types/commonTypes";
import { ReportListState } from "../views/reports/reportTypes";

/**
 * Indicates the status of the Redux Store loading
 */
export type ObjectDefinitionRemoteLoadingStatus =
  | "unknown" // loading status unknown
  | "loading" // currently loading the object from server
  | "fullyLoaded" // object is fully loaded from server
  | "loadingFailed"; // loading object from server failed

/**
 * Indicates the status of the Remote Saving action
 */
export type ObjectDefinitionRemoteSavingStatus =
  | "unknown" // saving status unknown
  | "saving" // currently saving the object from server
  | "success" // Successfully saved object remote
  | "error"; // Error saving object remote

/**
 * Default instance of [ObjectDefinitionState]
 */
export const defaultObjectDefinitionState: ObjectDefinitionState = {
  items: {},
  remoteLoadingStatus: "unknown",
  remoteSavingStatus: "unknown",
  error: "",
};

/**
 * Represents the Redux Store state for storing ObjectDefinitions.
 */
export interface ObjectDefinitionState {
  items: Dictionary<ObjectDefinition>;
  remoteLoadingStatus: ObjectDefinitionRemoteLoadingStatus;
  remoteSavingStatus: ObjectDefinitionRemoteSavingStatus;
  currentObject?: ObjectDefinition;
  error: string;
}

/**
 * Represents an ObjectDefinition in the DataStore.
 * @export
 * @interface ObjectDefinition
 */
export interface ObjectDefinition {
  /**
   * Key of the definition, together with version the composite unique identifier.
   * @type {string}
   * @memberof ObjectDefinition
   */
  key: string;
  /**
   * Version of the definition, together with key the composite unique identifier.
   *
   * @type {number}
   * @memberof ObjectDefinition
   */
  version: number;
  /**
   * Label is a more display friendly name of the definition.
   * @type {string}
   * @memberof ObjectDefinition
   */
  label: string;
  /**
   * Description of the definition, indicating its purpose.
   * @type {string}
   * @memberof ObjectDefinition
   */
  description: string;
  /**
   * List of fields of the definition
   * @type {ObjectDefinitionField[]}
   * @memberof ObjectDefinition
   */
  fields: ObjectDefinitionField[];
  /**
   * Server-side definition creation date
   * @type {Date}
   * @memberof ObjectDefinition
   */
  created: Date;
  /**
   * Server-side definition update date
   * @type {Date}
   * @memberof ObjectDefinition
   */
  updated: Date;
  /**
   * Indicates the status of the object with regard to server-side loading.
   * @type {ObjectDefinitionRemoteLoadingStatus}
   * @memberof ObjectDefinition
   */
  remoteLoadingStatus: ObjectDefinitionRemoteLoadingStatus;
  /**
   * Indicates the status of the object with regard to server-side saving.
   * @type {ObjectDefinitionRemoteSavingStatus}
   * @memberof ObjectDefinition
   */
  remoteSavingStatus: ObjectDefinitionRemoteSavingStatus;

  /**
   * Any additional data to represent  object definition is stored here.
   * @type {ObjectDefinitionOtherData}
   * @memberof ObjectDefinition
   */
  otherData: ObjectDefinitionOtherData;

  /**
   * Any additional data to represent  object definition is deleted.
   * @type {boolean}
   * @memberof ObjectDefinition
   */
  deleted: boolean;
}

export interface ObjectDefinitionOtherData {
  /**
   * Stores the Id of the form that will be used to display the report.
   * @type {string}
   * @memberof ObjectDefinitionOtherData
   */
  formId: string;
  cutFormId: string;
}

/**
 * Represents a field of an ObjectDefinition
 * @export
 * @interface ObjectDefinitionField
 */
export interface ObjectDefinitionField {
  label: string;
  /**
   * Identifier of the field
   * @type {string}
   * @memberof ObjectDefinitionField
   */
  key: string;
  /**
   * Whether the field is visible in the report when shown in mobile.
   * @type {boolean}
   * @memberof ObjectDefinitionField
   */
  visibleInMobile: boolean;
  /**
   * Whether the field is visible in the report when shown in other devices.
   * @type {boolean}
   * @memberof ObjectDefinitionField
   */
  visibleInOthers: boolean;
  /**
   * Type of the field indicating its content
   * @type {("text" | "integer" | "decimal" | "date" | "binary" | "bool" | "")}
   * @memberof ObjectDefinitionField
   */
  type: "text" | "integer" | "decimal" | "date" | "binary" | "bool" | "json";
}

/**
 * Default instance of the ObjectDefinition
 */
export const defaultObjectDefinition: ObjectDefinition = {
  key: "",
  label: "",
  description: "",
  version: 1,
  fields: [],
  created: new Date(),
  updated: new Date(),
  remoteLoadingStatus: "unknown",
  remoteSavingStatus: "unknown",
  otherData: { formId: EMPTY, cutFormId: EMPTY },
  deleted: false,
};

/**
 * Default instance of the ObjectDefinitionField
 */
export const defaultObjectDefinitionField: ObjectDefinitionField = {
  label: "",
  key: "",
  type: "text",
  visibleInOthers: false,
  visibleInMobile: false,
};

/**
 * Represents an ObjectData in the DataStore.
 * @export
 * @interface ObjectData
 */
export interface ObjectData {
  /**
   * Key of the definition, together with version the composite unique identifier.
   * @type {string}
   * @memberof ObjectData
   */
  key: string;
  /**
   * Version of the definition, together with key the composite unique identifier.
   *
   * @type {number}
   * @memberof ObjectData
   */
  version: number;
  /**
   * Id uniquely identifies an object definition data.
   * @type {string}
   * @memberof ObjectData
   */
  id: string;
  /**
   * Server-side definition creation date
   * @type {Date}
   * @memberof ObjectData
   */
  created: Date;
  /**
   * Server-side definition update date
   * @type {Date}
   * @memberof ObjectData
   */
  updated: Date;
  /**
   * Indicates the status of the object with regard to server-side loading.
   * @type {EntityLoadingStatus}
   * @memberof ObjectData
   */
  remoteLoadingStatus: EntityLoadingStatus;
  /**
   * Indicates whether the data is validated.
   * @type {Boolean}
   * @memberof ObjectData
   */
  remoteSavingStatus: EntitySavingStatus;
  /**
   * Indicates whether the data is validated.
   * @type {Boolean}
   * @memberof ObjectData
   */
  validated: boolean;
  /**
   * Indicates the dynamic process instance data.The data corresponds to the fields in object data definition.
   * @type {Object}
   * @memberof ObjectData
   */
  data: DynamicJson;
  /**
   * Version of the data.
   *
   * @type {number}
   * @memberof ObjectData
   */
  dataVersion: number;
}

/**
 * Represents a dynamic json object.
 * @export
 * @interface DynamicJson
 */
export interface DynamicJson {
  [key: string]: any;
}

export type SearchType = "EQUALS" | "CONTAINS" | "EQUAL" | "LIKE";

/**
 * Represents the request body of filter API call.
 * @export
 * @interface ObjectDataFilterBody
 */

export type FilterParam = {
  key: string;
  value: any;
  comparisonType: string;
};
export interface ObjectDataFilterBody {
  page?: number;
  pageSize?: number;
  filterParams?: FilterParam[];
  sortParams?: { param: string; order: string };
  key?: string;
  version?: number;
  properties?: string[];
  requestType?: string;
}

export const defaultObjectDataFilterBody: ObjectDataFilterBody = {
  page: 0,
  pageSize: 10,
  version: 1,
};

/**
 * Default instance of the ObjectDefinition
 */
export const defaultObjectData: ObjectData = {
  key: "",
  version: 1,
  id: "",
  data: {},
  validated: false,
  created: new Date(),
  updated: new Date(),
  remoteLoadingStatus: "unknown",
  remoteSavingStatus: "unknown",
  dataVersion: 1,
};

/**
 * Represents the Redux Store state for storing ObjectData.
 */
export interface ObjectDataState {
  items: Dictionary<ObjectData>;
  remoteLoadingStatus: EntityLoadingStatus;
  remoteSavingStatus: EntitySavingStatus;
  currentObject?: ObjectData;
  error: string;
  content: ObjectData[];
  totalPages: number;
  totalElements: number;
  elementsInPage: number;
  pageSize: number;
  page: number;
  lastPage: boolean;
  firstPage: boolean;
  isPagedResponse: boolean;
  [key: string]: any;
}

/**
 * Default instance of [ObjectDataState]
 */
export const defaultObjectDataState: ObjectDataState = {
  items: {},
  remoteLoadingStatus: "unknown",
  remoteSavingStatus: "unknown",
  currentObject: undefined,
  error: "",
  content: [],
  totalPages: 0,
  totalElements: 0,
  elementsInPage: 0,
  pageSize: 0,
  page: 0,
  lastPage: false,
  firstPage: false,
  isPagedResponse: true,
};

export interface RemoteObjectData {
  content: ObjectData[];
  count: number;
  page: number;
  pageSize: number;
}

export interface DataTableProps {
  filterBody: any;
  formType?: any;
  handleFilterData: (filterBody: ObjectDataFilterBody) => void;
  isMobileView: boolean;
  listState: ReportListState;
  objectDefinition: ObjectDefinition;
  remoteObjectData: RemoteObjectData;
  rerenderTable: boolean;
  setListState: React.Dispatch<React.SetStateAction<ReportListState>>;
  t: i18n.TFunction;
  handleSelectedRow: (rowData: string[]) => void;
}

export const defaultObjectDataSortOrder: SortParams = {
  direction: "desc",
  name: LAST_UPDATED,
};
export interface Option {
  display?: string;
  filter?: boolean;
  download?: boolean;
  sort?: boolean;
  sortDirection?: "asc" | "desc" | "none";
  fieldType?: any;
  filterType?: "custom";
  filterOptions?: Record<string, any>;
}
export interface DataItem {
  name: string;
  label: string;
  options: Option;
}
